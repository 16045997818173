<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <el-row class='table-header'>
          <el-col :span='24'>
            <el-tooltip effect='dark' content='刷新列表' placement='top-start'
                        v-if="buttonType=='icon'">
              <el-button type='primary' size='medium' icon='el-icon-refresh-left'
                         @click='initList' :loading='loadingStaus' :disabled='loadingStaus'>刷新列表
              </el-button>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-table
          v-loading='loadingStaus'
          :data='copyDataList'
          row-key='id'
          :key='tableKey'
          lazy
          :expand-row-keys='expandRowKeys'
          :load='load'
          ref='treeTable'
          :columns='columns'
          :tree-props="{hasChildren: 'hasChildren',children: 'children'}"
        >
          <el-table-column prop='id' label='ID' width='200'></el-table-column>
          <el-table-column prop='display_name' label='权限名称' width='200'></el-table-column>
          <el-table-column prop='name' label='权限标识' width='400'></el-table-column>
          <el-table-column prop='effect_uri' label='作用路由' width='400'></el-table-column>
          <el-table-column prop='description' label='备注' width='300'></el-table-column>
          <el-table-column width='200' align='center' label='操作'>
            <template slot-scope='scope'>
              <el-tooltip effect='dark' content='添加子权限' placement='top-start'
                          v-if="userPermissions.indexOf('permissions_create') != -1">
                <el-button size='mini' icon='iconfont icon-zengjiazicaidan'
                           @click='addButton(scope.row.id,scope.row.name)'></el-button>
              </el-tooltip>
              <el-tooltip effect='dark' content='编辑' placement='top-start'
                          v-if="userPermissions.indexOf('permissions_edit') != -1">
                <el-button size='mini' icon='el-icon-edit'
                           @click='editButton(scope.row.id,scope.row.parent)'></el-button>
              </el-tooltip>
              <el-tooltip effect='dark' content='删除' placement='top-start'>
                                    <span>
                                      <el-popover
                                        v-if="userPermissions.indexOf('permissions_delete') != -1"
                                        :ref="'el-popover-'+scope.$index"
                                        placement='top'
                                        width='150'>
                                        <p>确定要删除记录吗？</p>
                                        <div style='text-align: right; margin: 0;'>
                                          <el-button type='text' size='mini'
                                                     @click="$refs['el-popover-'+scope.$index].doClose()">取消</el-button>
                                          <el-button type='danger' size='mini'
                                                     @click='deleteButton(scope.row.id,scope.row)'>确定</el-button>
                                        </div>
                                        <el-button slot='reference' type='danger' size='mini'
                                                   icon='el-icon-delete'></el-button>
                                      </el-popover>
                                    </span>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </PageHeaderLayout>

    <ModalDialog :dialogData='dialogData' @dialogConfirm='handleConfirm' @dialogClose='dialogClose'>
      <template slot='content'>
        <el-form :model='formData' :rules='rules' ref='permissionsForm' label-position='right' label-width='96px'>
          <el-form-item label='权限名称' prop='display_name'>
            <el-input v-model='formData.display_name'></el-input>
          </el-form-item>
          <el-form-item label='权限标识' prop='name'>
            <el-input v-model='formData.name'>
              <template slot='prepend' v-if='isInherit && handleChild'>{{ namePrefix }}</template>
              <el-button slot='append' v-if='handleChild' type='danger' @click='toggleInput'>
                {{ isInherit ? '自定义' : '继承父级' }}
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item label='作用路由' prop='effect_uri'>
            <el-input type='textarea' v-model='formData.effect_uri'></el-input>
          </el-form-item>
          <el-form-item label='备注' prop='description'>
            <el-input type='textarea' v-model='formData.description'></el-input>
          </el-form-item>
        </el-form>
        <div>
          <h4>拥有该权限的角色：</h4>
          <div>
            <el-tag
              style='margin: 5px'
              v-for='tag in roleList'
              :key='tag.id'
              size='small'
              closable
              type='success' effect='plain'>
              {{ tag.display_name }}
            </el-tag>
          </div>
        </div>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ModalDialog from '@/components/ModalDialog'
import Sortable from 'sortablejs'
import { mapGetters } from 'vuex'

export default {
  name: 'PermissionsList',
  components: {
    PageHeaderLayout,
    ModalDialog
  },
  data() {
    return {
      loadingRefresh: false,
      loadingStaus: true,
      dialogData: {
        visible: false,
        title: '',
        width: '50%',
        loading: true,
        modal: false
      },
      columns: [
        {
          title: 'ID',
          value: 'id',
          width: 60
        },
        {
          title: '权限名称',
          operation: true, // 作为展开操作列
          value: 'display_name',
          width: 300
        },
        {
          title: '权限标识',
          value: 'name',
          width: 200
        },
        {
          title: '作用路由',
          value: 'effect_uri_alias',
          value_alias: 'effect_uri',
          width: 300
        },
        {
          title: '备注',
          value: 'description'
        }
      ],
      // 表格列表数据
      permissionsList: [],
      // 表单结构
      formData: {
        display_name: ''
      },
      // 表单验证
      rules: {
        display_name: [{ required: true, message: '输入权限名称', trigger: 'blur' }],
        name: [{ required: true, message: '输入权限标识', trigger: 'blur' }]
      },
      // 定义权限标识输入方式，是否继成
      // 在添加修改子权限时候生效
      isInherit: true,
      namePrefix: '',
      handleChild: false,
      // 定义表格拖拽排序
      sortable: null,
      copyDataList: [],
      editRow: {},
      expandRowKeys: [],
      loadIds: [],
      tableKey: 0,
      //该权限的角色
      roleList:[]
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  methods: {
    //同步权限
    syncPermission() {
      this.$confirm('此操作将重置权限为后台设定权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.refreshPermissions()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消',
          duration: 1000,
          showClose: true
        })
      })
    },
    async refreshPermissions() {
      this.loadingRefresh = true
      let { total } = await this.$api.refreshPermissions()
      this.$message({
        type: 'success',
        message: `刷新权限：${total} 条`,
        duration: 1000,
        showClose: true
      })
      this.loadingRefresh = false
      await this.initList()
    },
    // 响应添加按钮
    async addButton(pid, name) {
      // 添加子权限，拼接的前缀
      if (name) {
        this.namePrefix = name + '_'
      }
      this.dialogData.visible = true
      this.dialogData.title = pid ? '添加子权限' : '添加权限'
      this.initForm(pid)
    },
    // 响应编辑按钮
    async editButton(id, parent) {
      this.editRow = { id: id, parent: parent }
      this.dialogData.visible = true
      this.dialogData.title = '编辑权限'
      let { info } = await this.$api.getPermissionsInfo(id)
      this.formData = info
      // 编辑子权限，拼接的前缀
      if (parent) {
        this.namePrefix = parent.name + '_'
      }
      this.isInherit = false
      await this.initForm(info.parent_id)
      // 加载 权限对应的角色
      let { role_list } = await this.$api.getRoleByPermission(info.name)
      this.roleList = role_list
      console.log(role_list)
    },
    // 初始化form，将添加，编辑共同数据抽取出来
    async initForm(pid) {
      this.formData.parent_id = pid
      this.dialogData.loading = false
      if (pid) {
        this.handleChild = true
      } else {
        this.handleChild = false
      }
    },
    // 切换权限标识的输入方式，继成父级、自定义
    toggleInput() {
      this.isInherit = !this.isInherit
    },
    // form数据提交，请求接口
    async formSubmit() {
      // let ids = this.editRow.id
      // let ids = this.$refs['treeTable'].getExpandIds()
      // 权限标识，如果需要拼接前缀，处理数据
      if (this.isInherit && this.handleChild) {
        this.formData.name = this.namePrefix + this.formData.name
      }

      let id = await this.$api.savePermissions(this.formData)
      if (id) {
        let { list } = await this.$api.getPermissionsList()
        this.permissionsList = list
      }
      this.initData()
      // this.$nextTick(() => {
      //   this.$refs['treeTable'].initTableExpand(ids)
      // })
      // this.$message.success('保存成功!')
      this.$message({
        showClose: true,
        message: '保存成功',
        type: 'success',
        duration: 2000
      })
      await this.initList()
    },
    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deletePermissions(id)
      if (info == 'ok') {
        this.$message.success('删除成功!')
        await this.initList()
      } else {
        this.$message.error(info)
      }

    },
    // 处理模态框，确定事件
    handleConfirm() {
      // 调用组件的数据验证方法
      this.$refs['permissionsForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // 处理模态框，关闭事件
    dialogClose() {
      this.initData()
    },
    // 初始化数据
    initData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.dialogData.visible = false
        this.dialogData.loading = true
        this.isInherit = true
        this.namePrefix = ''
        this.formData = {
          display_name: ''
        }
        this.$refs['permissionsForm'].resetFields()
      })
    },
    async initList() {
      this.loadingStaus = true
      this.tableKey += 1

      let { list } = await this.$api.getPermissionsList()
      this.permissionsList = JSON.parse(JSON.stringify(list))
      this.copyDataList = JSON.parse(JSON.stringify(list)).map(item => { // 展示数据
        // hasChildren 表示需要展示一个箭头图标
        item.hasChildren = item.children && item.children.length > 0
        // 只展示一层
        // 如果有children数据，会自动加载，就不是懒加载了，也可以配置tree-props里面的children为其他字段
        item.children = null
        // 记住层级关系
        item.idList = [item.id]
        return item
      })

      this.loadingStaus = false

    },
    // 拖拽排序数据提交，请求接口
    async dragSortSubmit(orderIds) {
      let ids = this.$refs['treeTable'].getExpandIds()
      let info = await this.$api.orderPermissions(orderIds)
      if (info == 'ok') {
        let { list } = await this.$api.getPermissionsList()
        this.permissionsList = []
        this.$nextTick(() => {
          this.permissionsList = list
          this.$nextTick(() => {
            this.$refs['treeTable'].initTableExpand(ids)
          })
        })
      }
      this.$message.success('保存成功!')
      await this.initList()

    },
    // 表格拖拽排序，同层级移动有效果
    dragSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      this.sortable = Sortable.create(el, {
        handle: '.drag-handle',
        setData: function(dataTransfer) {
          dataTransfer.setData('Text', '')
          // to avoid Firefox bug
          // Detail see : https://github.com/RubaXa/Sortable/issues/1012
        },
        onEnd: evt => {
          let oldId = evt.item.querySelectorAll('.drag-handle')[0].dataset.id
          let Ids = []
          let orderIds = []
          let newList = []
          let tmp = el.querySelectorAll('.drag-handle')
          for (let i = 0, len = tmp.length; i < len; i++) {
            newList[tmp[i].dataset.id] = { 'parent_id': tmp[i].dataset.parent_id, 'depth': tmp[i].dataset.depth }
            Ids.push(tmp[i].dataset.id)
          }
          // 处理组合实际请求后台数据
          for (let i = 0, len = Ids.length; i < len; i++) {
            if (newList[oldId].parent_id == newList[Ids[i]].parent_id && newList[oldId].depth == newList[Ids[i]].depth) {
              orderIds.push(Ids[i])
            }
          }
          this.dragSortSubmit(orderIds)
        }
      })
    },
    findChild(idList) {
      // 层级关系备份
      const idCopy = JSON.parse(JSON.stringify(idList))
      // 查找下一层数据
      let resolveArr = this.permissionsList
      let id
      // eslint-disable-next-line
      while (id = idList.shift()) {
        const tarItem = resolveArr.find(item => item.id === id)
        tarItem.loadedChildren = true
        resolveArr = tarItem.children
      }

      // 处理下一层数据的属性
      resolveArr = JSON.parse(JSON.stringify(resolveArr))
      resolveArr.forEach(item => {
        item.hasChildren = item.children && item.children.length > 0
        item.children = null
        // 此处需要深拷贝，以防各个item的idList混乱
        item.idList = JSON.parse(JSON.stringify(idCopy))
        item.idList.push(item.id)
      })
      return resolveArr
    },
    load(tree, treeNode, resolve) {
      this.loadIds = tree.idList
      let resolveArr = this.findChild(tree.idList)
      // 标识已经加载子节点
      tree.loadedChildren = true
      // 渲染子节点
      resolve(resolveArr)
    }
  },
  async mounted() {
    await this.initList()
  }
}
</script>

<style lang='stylus'>
.el-button
  margin-right 4px
  margin-bottom 4px

.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff
</style>
